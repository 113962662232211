import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { Component } from "react";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import ChartContainer from "./ChartContainer";
import { EVENT_CODE } from "./Constants";

function MemoryUsageChart(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <ComposedChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          //type="number"
          tickFormatter={(timeStr) => moment(timeStr).format("MM/DD HH:mm")}
          domain={['auto', 'auto']}
        />
        <YAxis type="number" />
        <Legend />
        <Bar dataKey={props.field} fill="#8884d8" name="HDD Root percentage" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

class SystemResourceUsage extends Component {
  render() {
    const { data } = this.props;

    const systemResourceUsageEvents = data
      .filter((e) => e.code === EVENT_CODE.EVENT_SYSTEM_RESOURCES)
      .map((e) => {
        const payload = JSON.parse(e.payload.payload);
        return {
          ...e,
          time: e.time,
          hdd_root_pct: payload.hdd_root_pct,
          hdd_home_pct: payload.hdd_home_pct,
          ram_pct: payload.ram_pct
        };
      });

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
        <ChartContainer title="RAM usage (%)">
          <MemoryUsageChart
              data={systemResourceUsageEvents}
              field="ram_pct"
          />
        </ChartContainer>
        </Grid>
        <Grid item xs={12} md={8}>
        <ChartContainer title="/root HDD usage (%)">
          <MemoryUsageChart
              data={systemResourceUsageEvents}
              field="hdd_root_pct"
          />
        </ChartContainer>
        </Grid>
        <Grid item xs={12} md={8}>
        <ChartContainer title="/home HDD usage (%)">
          <MemoryUsageChart
              data={systemResourceUsageEvents}
              field="hdd_home_pct"
          />
        </ChartContainer>
        </Grid>
      </Grid>
    );
  }
}

export default SystemResourceUsage;
