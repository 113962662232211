import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import MenuIcon from "@material-ui/icons/Menu";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import TimelineIcon from "@material-ui/icons/Timeline";
import clsx from "clsx";
import { sub } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import DateRangePicker from "./DateRangePicker";
import { eventFetcherStore } from "./EventFetcherStore";
import { EventFetcherDisplay } from "./EventFetcherDisplay";
import { FilterCheckbox } from "./FilterMenu";
import HostnameSelector from "./HostnameSelector";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ paddingBottom: "1em" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.pickit3d.com/">
        Pickit NV
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  timePicker: {
    display: "flex",
    padding: "1em 0",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default observer((props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    open: true,
  });

  const handleDrawerOpen = () => {
    setState({ ...state, open: true });
  };
  const handleDrawerClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, state.open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              state.open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Pickit Dashboard{" "}
            {eventFetcherStore.hostname
              ? `- ${eventFetcherStore.hostname} / ${eventFetcherStore.database}`
              : ""}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !state.open && classes.drawerPaperClose
          ),
        }}
        open={state.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            selected={eventFetcherStore.view === "home"}
            onClick={async () => (eventFetcherStore.view = "home")}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
          <ListItem
            button
            selected={eventFetcherStore.view === "custom"}
            onClick={async () => (eventFetcherStore.view = "custom")}
          >
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary="Custom events" />
          </ListItem>

          <ListItem
            button
            selected={eventFetcherStore.view === "system-resource-usage"}
            onClick={async () => (eventFetcherStore.view = "system-resource-usage")}
          >
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText primary="System resources" />
          </ListItem>

          <ListItem
            button
            selected={eventFetcherStore.view === "analysis"}
            onClick={async () => (eventFetcherStore.view = "analysis")}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Pick analysis" />
          </ListItem>
          <ListItem
            button
            selected={eventFetcherStore.view === "calibration"}
            onClick={async () => (eventFetcherStore.view = "calibration")}
          >
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Calibration analysis" />
          </ListItem>
          <ListItem
            button
            disabled={!eventFetcherStore.hostname}
            component="a"
            href={`http://${eventFetcherStore.hostname}/`}
            target="_blank"
          >
            <ListItemIcon>
              <OpenInBrowserIcon />
            </ListItemIcon>
            <ListItemText primary="Web interface" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader inset>Quick access</ListSubheader>
          <ListItem
            button
            disabled={!eventFetcherStore.hostname}
            onClick={async () => {
              eventFetcherStore.startTime = sub(new Date(), { hours: 1 });
              eventFetcherStore.endTime = new Date();
            }}
          >
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Last hour" />
          </ListItem>
          <ListItem
            button
            disabled={!eventFetcherStore.hostname}
            onClick={async () => {
              eventFetcherStore.startTime = sub(new Date(), { days: 1 });
              eventFetcherStore.endTime = new Date();
            }}
          >
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Last 24h" />
          </ListItem>
          <ListItem
            button
            disabled={!eventFetcherStore.hostname}
            onClick={async () => {
              eventFetcherStore.startTime = sub(new Date(), { weeks: 1 });
              eventFetcherStore.endTime = new Date();
            }}
          >
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Last week" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg">
          {!eventFetcherStore.hostname && (
            <>
              <br></br>
              <Typography variant="h4" color="textSecondary" align="center">
                Select a system
              </Typography>
              <Box justifyContent="center" className={classes.timePicker}>
                <HostnameSelector
                  hostname={eventFetcherStore.hostname}
                  updateHostname={(newState) => {
                    eventFetcherStore.hostname = newState.hostname;
                  }}
                />
              </Box>
            </>
          )}
          {eventFetcherStore.hostname && (
            <>
              <Box justifyContent="center" className={classes.timePicker}>
                <DateRangePicker
                  startTime={eventFetcherStore.startTime}
                  endTime={eventFetcherStore.endTime}
                  updateTimes={({ startTime, endTime }) => {
                    eventFetcherStore.startTime = startTime;
                    eventFetcherStore.endTime = endTime;
                  }}
                />
                <FilterCheckbox />
              </Box>
              <EventFetcherDisplay />
            </>
          )}
        </Container>

        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
});
