import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

class ChartContainer extends Component {
  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h6" align="center">
          {this.props.title}
        </Typography>
        <br />
        {this.props.children}
      </Paper>
    );
  }
}

export default withStyles(useStyles)(ChartContainer);
