import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { Component } from "react";

import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChartContainer from "./ChartContainer";
import { COLORS, EVENT_CODE, DETECTION_STATUS } from "./Constants";

class Overview extends Component {
  render() {
    const { data } = this.props;
    const detectionEvents = data
      .filter((e) => e.code === EVENT_CODE.DETECTION_FINISHED)
      .map((e) => {
        const payload = JSON.parse(e.payload.payload);
        return {
          ...e,
          ...payload,
        };
      });

    const start = moment(data[0].time);
    const end = moment(data[data.length - 1].time);
    const duration = moment.duration(end.diff(start));
    
    const median = (arr) => {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    };
    const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
    const arrAvg = (arr) => arrSum(arr) / arr.length;

    const minMaxAvg = [
      {
        name: "Pickable objects",
        arr: detectionEvents.map((e) => e.n_valid),
      },
      {
        name: "Unpickable objects",
        arr: detectionEvents.map((e) => e.n_unpickable),
      },
      {
        name: "Detection time",
        arr: detectionEvents.map((e) => e.t_detection),
        unit: "s",
      },
      {
        name: "Image capture time",
        arr: detectionEvents.map((e) => e.t_capture),
        unit: "s",
      },
    ];

    const keyVal = [
      {
        name: "Start",
        val: start.format("l LT"),
      },
      {
        name: "End",
        val: end.format("l LT"),
      },
      {
        name: "Duration",
        val: `${duration.days()} d ${duration.hours()} h ${duration.minutes()} min`,
      },
      {
        name: "Number of detections",
        val: detectionEvents.length,
      },
    ];

    // Pickability ratio
    const objectDetectedEvents = detectionEvents
      .filter(
        (e) =>
          e.status === DETECTION_STATUS.SUCCESS &&
          e.n_valid + e.n_unpickable > 0
      )
      .map((e) => ({
        ...e,
        pickability_ratio: e.n_valid / (e.n_valid + e.n_unpickable),
      }));
    const meanPickabilityRatio = arrAvg(
      objectDetectedEvents.map((e) => e.pickability_ratio)
    );

    const roiVolumeEvents = detectionEvents
    .map((e) => ({
        ...e,
        occupied_roi_volume_ratio: 100 * e.occupied_roi_volume / e.roi_volume
      }))

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keyVal.map((e, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{`${e.val}${e.unit || ""}`}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={7}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell>Median</TableCell>
                  <TableCell>Min</TableCell>
                  <TableCell>Max</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {minMaxAvg.map((e, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{`${median(e.arr).toFixed(1)}${
                        e.unit || ""
                      }`}</TableCell>
                      <TableCell>{`${Math.min(...e.arr).toFixed(1)}${
                        e.unit || ""
                      }`}</TableCell>
                      <TableCell>{`${Math.max(...e.arr).toFixed(1)}${
                        e.unit || ""
                      }`}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer
            title={`Object pickability (${
              (100 * meanPickabilityRatio).toFixed(1)
            }% average pickability)`}
          >
            <ResponsiveContainer width="95%" height={300}>
              <AreaChart data={detectionEvents} syncId="overview-detection">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(timeStr) => moment(timeStr).format("DD-MMM HH:MM")}

                />
                <YAxis />
                <Tooltip
                  labelFormatter={(timeStr) => moment(timeStr).format("DD-MMM HH:mm")}
                />
                <Legend verticalAlign="top" />
                <Area
                  dataKey="n_valid"
                  stackId="a"
                  stroke={COLORS[0]}
                  fill={COLORS[0]}
                  name="Pickable"
                />
                <Area
                  dataKey="n_unpickable"
                  stackId="a"
                  stroke={COLORS[1]}
                  fill={COLORS[1]}
                  name="Unpickable"
                />
                <Brush
                  dataKey="time"
                  type="number"
                  height={20}
                  tickFormatter={(timeStr) => moment(timeStr).format("DD-MMM HH:mm")}
                />
              </AreaChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer
            title="Occupied ROI volume (% of total volume)"
          >
            <ResponsiveContainer width="95%" height={300}>
              <AreaChart data={roiVolumeEvents} syncId="overview-detection">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(timeStr) => moment(timeStr).format("DD-MMM HH:mm")}
                />
                <YAxis unit="%" />
                <Tooltip
                  labelFormatter={(timeStr) => moment(timeStr).format("DD-MMM HH:mm")}
                />
                <Area
                  dataKey="occupied_roi_volume_ratio"
                  stroke={COLORS[4]}
                  fill={COLORS[4]}
                  name="Volume"
                  unit="%"
                />
              </AreaChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>
      </Grid>
    );
  }
}

export default Overview;
