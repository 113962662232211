import {
  Badge,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  TextField,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Observer, observer, useLocalStore } from "mobx-react";
import { eventFetcherStore, parsePayloadByType } from "./EventFetcherStore";
import _ from "lodash";
import { ListItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    // border: "1px solid",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: "1fr 1fr",
  },
}));

const useFilterHook = (type) => {
  return React.useMemo(() => {
    const eventData = eventFetcherStore.eventData;
    const uniqueData = _.uniq(
      eventData.map((x) => parsePayloadByType(x, type))
    ).filter((x) => x);
    return _.orderBy(uniqueData);
  }, [eventFetcherStore.eventData]);
};

export const FilterCheckbox = observer(() => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setups = useFilterHook("setup");
  const products = useFilterHook("product");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <IconButton
        disabled={eventFetcherStore.eventData.length === 0}
        onClick={handleClick}
        ref={(node) => {
          // setAnchorEl(node);
        }}
      >
        <Badge
          invisible={eventFetcherStore.filtersActiveCount === 0}
          badgeContent={eventFetcherStore.filtersActiveCount}
          color="primary"
        >
          <FilterListIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.paper}>
          <FilterSelectionBox
            title="Setup"
            data={setups}
            activeFilters={eventFetcherStore.setupFilter}
            updateFilter={(newFilter) => {
              eventFetcherStore.setupFilter = newFilter;
            }}
          />
          <FilterSelectionBox
            title="Product"
            data={products}
            activeFilters={eventFetcherStore.productFilter}
            updateFilter={(newFilter) => {
              eventFetcherStore.productFilter = newFilter;
            }}
          />
        </div>
      </Popover>
    </div>
  );
});

const useFilterSelectionStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gap: "1rem",
    alignContent: "start",
  },
  list: {
    maxHeight: 300,
    overflow: "auto",
  },
  utilActions: {
    display: "grid",
    justifyContent: "end",
    gap: "0.5rem",
    gridTemplateColumns: "auto auto",

    "& > *": {
      cursor: "pointer",
    },
  },
}));

const FilterSelectionBox = observer((props) => {
  const localState = useLocalStore(() => ({
    filterText: "",
  }));
  const classes = useFilterSelectionStyles();
  const filteredData = React.useMemo(
    () =>
      (props.data || []).filter(
        (x) =>
          !localState.filterText ||
          x.toLowerCase().includes(localState.filterText.toLowerCase())
      ),
    [localState.filterText, props.data]
  );

  const handleCheck = (filterValue, shouldAdd) => () => {
    if (props.activeFilters === null) {
      props.updateFilter(props.data.filter((x) => x !== filterValue));
    } else if (shouldAdd && props.activeFilters.length === props.data.length - 1) {
      props.updateFilter(null);
    } else if (shouldAdd) {
      props.updateFilter([...props.activeFilters, filterValue]);
    } else {
      props.updateFilter(props.activeFilters.filter((x) => x !== filterValue));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.utilActions}>
        <Typography
          variant="caption"
          color="primary"
          onClick={() => {
            localState.filterText = "";
            props.updateFilter(null);
          }}
        >
          Select all
        </Typography>

        <Typography
          variant="caption"
          color="primary"
          onClick={() => {
            localState.filterText = "";
            props.updateFilter([]);
          }}
        >
          Select none
        </Typography>
      </div>

      {/* some individual render context  */}
      <Observer>
        {() => (
          <TextField
            label={props.title}
            value={localState.filterText}
            onChange={(e) => {
              localState.filterText = e.target.value;
            }}
            type="search"
          />
        )}
      </Observer>

      <List className={classes.list}>
        {filteredData.map((x) => {
          const checked =
            props.activeFilters === null ||
            props.activeFilters.includes(x);
          return (
            <ListItem button onClick={handleCheck(x, !checked)} dense key={x}>
              <ListItemText primary={`${x}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleCheck(x, !checked)}
                  checked={checked}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        {filteredData.length === 0 && (
          <ListItem dense>
            <ListItemText
              style={{ color: "#999" }}
              primary="No items to display"
            />
          </ListItem>
        )}
      </List>
    </div>
  );
});
