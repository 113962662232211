import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as d3 from "d3";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Matrix4, Quaternion, Vector3 } from "three";
import { COLORS, EVENT_CODE, DETECTION_STATUS, PICK_STATUS } from "./Constants";
import ChartContainer from "./ChartContainer";

function MissPicksBarChart(props) {
  return (
    <ResponsiveContainer width="95%" height={180}>
      <BarChart data={props.data} barCategoryGap={0} barGap={0} syncId="histo">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="time"
          domain={props.domain}
          tickFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <YAxis type="number" />
        <Tooltip
          labelFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <Legend />
        <Bar
          stackId="a"
          dataKey="picks"
          fill={COLORS[0]}
          name="Picks per min"
        />
        <Bar
          stackId="a"
          dataKey="mispicks"
          fill={COLORS[1]}
          name="Mispicks per min"
        />
        <Brush
          dataKey="time"
          type="number"
          height={20}
          tickFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

function EventBarChart(props) {
  return (
    <ResponsiveContainer width="95%" height={180}>
      <BarChart data={props.data} barCategoryGap={0} barGap={0} syncId="histo">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="time"
          domain={props.domain}
          tickFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <YAxis type="number" />
        <Tooltip
          labelFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <Legend />
        <Bar
          stackId="a"
          dataKey="no_obj"
          fill={COLORS[0]}
          name="No object found"
        />
        <Bar
          stackId="a"
          dataKey="unreachable"
          fill={COLORS[1]}
          name="Unreachable object"
        />
        <Bar
          stackId="a"
          dataKey="collision"
          fill={COLORS[2]}
          name="Collision caught"
        />
        <Bar
          stackId="a"
          dataKey="no_image"
          fill={COLORS[6]}
          name="No camera image"
        />
        <Bar
          stackId="a"
          dataKey="empty_roi"
          fill={COLORS[4]}
          name="Empty ROI"
        />
        <Bar dataKey="bin_finished" fill={COLORS[5]} name="Switch bin" />
        <Bar dataKey="cam_connected" fill={COLORS[7]} name="Cam connected" />
        <Bar dataKey="cam_disconnected" fill={COLORS[8]} name="Cam disconnected" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function AvgMissPickRateBarChart(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <AreaChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          type="number"
          domain={props.domain}
          tickFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <YAxis unit="%" tickFormatter={(v) => `${(100 * v).toFixed(0)}`} />
        <Tooltip
          formatter={(v) => `${(100 * v).toFixed(2)}%`}
          labelFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <Legend />
        <Area
          dataKey="PickRatio"
          stackId="a"
          stroke={COLORS[0]}
          fill={COLORS[0]}
          name="Pick ratio"
        />
        <Area
          dataKey="MissPickRatio"
          stackId="a"
          stroke={COLORS[1]}
          fill={COLORS[1]}
          name="Mispick ratio"
        />
        <Brush
          dataKey="time"
          type="number"
          height={20}
          tickFormatter={(timeStr) => moment(timeStr).format("HH")}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function OccupiedVolumeOnBinSwitchHistogram(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" dataKey="pct"/>
        <YAxis type="number" />
        <Legend />
        <Bar dataKey="amount" fill="#8884d8" name="Percentage" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function PicksPerBinChart(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="time"
          domain={props.domain}
          tickFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <YAxis type="number" />
        <Tooltip
          labelFormatter={(timeStr) => moment(timeStr).format("HH:mm")}
        />
        <Bar dataKey="payload" fill={COLORS[4]} name="Picked objects" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function LastDetectionBeforeBinSwitch(detections, binSwitches) {
  let i = 0;  // Current detection event index.
  let j = 0;  // Current bin switch index.

  // Get first bin switch after first detection event.
  while (j < binSwitches.length && detections[i].time > binSwitches[j].time) {
    ++j;
  }

  let lastDetectionBeforeBinSwitch = []
  for (; i + 1 < detections.length && j < binSwitches.length; ++i) {
    if (detections[i].time < binSwitches[j].time &&
        detections[i + 1].time > binSwitches[j].time) {
      // Current detection comes before next bin switch and
      // next detection comes after it.
      lastDetectionBeforeBinSwitch.push(detections[i]);
      ++j;
    }
  }
  return lastDetectionBeforeBinSwitch;
}

function CycleTimeHistogram(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" dataKey="seconds" domain={props.domain} />
        <YAxis type="number" />
        <Tooltip
          labelFormatter={(timeStr) => timeStr + " seconds"}
        />
        <Legend />
        <Bar dataKey="amount" fill="#8884d8" name="#Cycles" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function computeTimeDifferences(events) {
  const sa1 = events.slice(0, -1); // Last event removed.
  const sa2 = events.slice(1); // First event removed.
  return sa1.map((sa1_val, idx) => {
    return (sa2[idx].time - sa1_val.time) / 1000;
  });
}

function computeCycleTimes(pickStatusEvents) {
  const cycleCompletedEvents = pickStatusEvents.filter((e) =>
    [PICK_STATUS.CYCLE_COMPLETED].includes(
      e.event_code
    )
  );

  if (cycleCompletedEvents.length === 0) {
    // Backwards compatibility: calculate cycle time based on
    // time between PICK_SUCCESS events when there are no CYCLE_COMPLETED events.
    const pickSuccessEvents = pickStatusEvents.filter((e) =>
      [PICK_STATUS.PICK_SUCCESS].includes(
        e.event_code
      )
    );

    return computeTimeDifferences(pickSuccessEvents);
  } else {
    return computeTimeDifferences(cycleCompletedEvents);
  }
}

class PickSuccess extends Component {
  render() {
    const { data } = this.props;

    if (data === undefined) {
      return <p>No Events.</p>;
    }

    const pickStatusEvents = data
      .filter((e) => e.code === EVENT_CODE.PICK_STATUS)
      .map((e) => {
        const payload = JSON.parse(e.payload.payload);
        const rot = payload.pose.orientation;
        const quat = new Quaternion(rot.x, rot.y, rot.z, rot.w);
        let unitZ = new Vector3();
        new Matrix4()
          .makeRotationFromQuaternion(quat)
          .extractBasis(new Vector3(), new Vector3(), unitZ);
        const angle = new Vector3(0, 0, -1).angleTo(unitZ); // Negative Z-axis as expressed in robot coordinates.
        return {
          ...e,
          ...payload,
          angle: (angle * 180) / Math.PI,
          uintZ: unitZ,
        };
      });

    var time_range = [data[0].time, data[data.length - 1].time];

    if (pickStatusEvents.length === 0) {
      // No pick success data published from robot.
      time_range = [data[0].time, data[data.length - 1].time];
    } else {
      // Pick success data published from robot.
      // Only look at avent that had PickStatus (production mode of the cell).
      time_range = [
        pickStatusEvents[0].time,
        pickStatusEvents[pickStatusEvents.length - 1].time,
      ];
    }

    const detectionEvents = data
      .filter(
        (e) =>
          e.code === EVENT_CODE.DETECTION_FINISHED &&
          e.time >= time_range[0] &&
          e.time <= time_range[1]
      )
      .map((e) => {
        const payload = JSON.parse(e.payload.payload);
        return {
          ...e,
          ...payload,
        };
      });
    const pickOrMissPickEvents = pickStatusEvents.filter((e) =>
      [PICK_STATUS.PICK_SUCCESS, PICK_STATUS.PICK_FAILURE].includes(
        e.event_code
      )
    );
    const pickSuccessEvents = pickOrMissPickEvents.filter(
      (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
    );
    const pickFailureEvents = pickOrMissPickEvents.filter(
      (e) => e.event_code === PICK_STATUS.PICK_FAILURE
    );
    const collisionPicks = pickStatusEvents.filter(
      (e) => e.event_code === PICK_STATUS.ROBOT_COLLISION_CAUGHT
    );
    const unreachablePicks = pickStatusEvents.filter(
      (e) => e.event_code === PICK_STATUS.UNREACHABLE_OBJECT
    );

    const pickSuccessRate =
      pickSuccessEvents.length / pickOrMissPickEvents.length;
    const pickEventsYPositive = pickOrMissPickEvents.filter(
      (e) => e.pose.position.y >= 0
    );
    const pickEventsYNegative = pickOrMissPickEvents.filter(
      (e) => e.pose.position.y < 0
    );
    const pickSucessRateYPositive =
      pickEventsYPositive.filter(
        (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
      ).length / pickEventsYPositive.length;
    const pickSucessRateYNegative =
      pickEventsYNegative.filter(
        (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
      ).length / pickEventsYNegative.length;

    // Status events (from robot and from pickit)
    const detectionStatusEvents = detectionEvents.map((x) => ({
      time: x.time,
      status:
        x.status === DETECTION_STATUS.SUCCESS && x.n_valid === 0
          ? DETECTION_STATUS.NO_OBJ_FOUND
          : x.status, // Create a no object_found status.
    }));
    const robotStatusEvents = pickStatusEvents.map((x) => ({
      time: x.time,
      status: x.event_code,
    }));
    const cameraConnectionEvents = data.filter(
      (e) =>
        [EVENT_CODE.CAMERA_CONNECTED, EVENT_CODE.CAMERA_DISCONNECTED].includes(e.code) &&
        e.time >= time_range[0] &&
        e.time <= time_range[1]
    ).map(x => ({
      time: x.time,
      status: x.code
    }));

    const statusEvents = detectionStatusEvents.concat(robotStatusEvents).concat(cameraConnectionEvents);
    const binFinishedData = pickStatusEvents.filter(
      (p) => p.event_code === PICK_STATUS.BIN_FINISHED
    );

    const lastDetectionBeforeBinSwitch = LastDetectionBeforeBinSwitch(detectionEvents, binFinishedData);
    const lastDetectionBeforeBinSwitchVolumePct = lastDetectionBeforeBinSwitch.map((x) => ({
      // Round to 0.1%.
      pct: Math.round(1000 * x.occupied_roi_volume / x.roi_volume) / 10
    }));
    const lastDetectionBeforeBinSwitchRounded = _.toPairs(
      _.countBy(lastDetectionBeforeBinSwitchVolumePct, 'pct')
    ).map((pair) => {
      return {
        pct: pair[0],
        amount: pair[1],
      };
    });

    const noImagePicks = statusEvents.filter(
      (e) => e.status === DETECTION_STATUS.NO_IMAGE
    );

    // HISTOGRAM (events per min)
    const marshalling = {
      // Pick success
      [PICK_STATUS.PICK_SUCCESS]: "picks",
      [PICK_STATUS.PICK_FAILURE]: "mispicks",
      // Detection error code
      [DETECTION_STATUS.NO_OBJ_FOUND]: "no_obj",
      [DETECTION_STATUS.EMPTY_ROI]: "empty_roi",
      [DETECTION_STATUS.NO_IMAGE]: "no_image",
      // Robot error codes
      [PICK_STATUS.UNREACHABLE_OBJECT]: "unreachable",
      [PICK_STATUS.ROBOT_COLLISION_CAUGHT]: "collision",
      // Other events
      [PICK_STATUS.BIN_FINISHED]: "bin_finished",
      [EVENT_CODE.CAMERA_CONNECTED]: "cam_connected",
      [EVENT_CODE.CAMERA_DISCONNECTED]: "cam_disconnected",
    };
    const timeBins = d3.timeMinutes(time_range[0], time_range[1]);
    var histogram = d3
      .bin()
      .value(function (d) {
        return new Date(d.time);
      })
      .thresholds(timeBins);
    const dataHist = histogram(statusEvents).map((x) => ({
      time: +x.x1,
      ..._(x)
        .countBy((y) => y.status)
        .mapKeys((v, k) => marshalling[k] ?? k)
        .value(),
    }));

    // HISTOGRAM (events per 10 min)
    const timeBins_h = d3.timeMinutes(time_range[0], time_range[1], 10);
    var histogram_h = d3
      .bin()
      .value(function (d) {
        return new Date(d.time);
      })
      .thresholds(timeBins_h);
    const dataHist_h = histogram_h(pickStatusEvents)
      .map((x) => ({
        time: +x.x1,
        ..._(x)
          .countBy((y) => y.event_code)
          .mapKeys((v, k) => marshalling[k] ?? k)
          .value(),
      }))
      .map((x) => ({
        ...x,
        picks: x.picks === undefined ? 0 : x.picks,
        mispicks: x.mispicks === undefined ? 0 : x.mispicks,
      }))
      .reduce((acc, x) => {
        acc.push({
          ...x,
          MissPickRatio:
            x.mispicks !== undefined ? x.mispicks / (x.picks + x.mispicks) : 0,
          PickRatio:
            x.picks !== undefined ? x.picks / (x.picks + x.mispicks) : 0,
        });
        return acc;
      }, []);

    const cycleTimes = computeCycleTimes(pickStatusEvents)
    const cycleTimesRounded = _.toPairs(
      _.countBy(cycleTimes, Math.round)
    ).map((pair) => {
      return {
        seconds: pair[0],
        amount: pair[1],
      };
    });
    const minCycleTime = Math.ceil(Math.min(...cycleTimes));
    const maxCycleTime = Math.ceil(Math.max(...cycleTimes));

    const median = (arr) => {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    };

    const minMaxMedian = [
      {
        name: "Picks per min",
        arr: dataHist.filter((e) => e.picks !== undefined).map((e) => e.picks),
        precision: 1
      },
      {
        name: "Picks per bin",
        arr: binFinishedData.map((e) => e.payload),
        precision: 1
      },
      {
        name: "Occupied bin volume before bin switch",
        arr: lastDetectionBeforeBinSwitch.map((e) => 100 * e.occupied_roi_volume / e.roi_volume),
        unit: "%",
        precision: 2
      },
      {
        name: "Cycle time",
        arr: cycleTimes.map((e) => e),
        unit: "s",
        precision: 1
      },
    ];

    const metricValue = [
      {
        name: "Pick success rate",
        value: `${(100 * pickSuccessRate).toFixed(1)}%`,
      },
      {
        name: " - for objects with Y ≥ 0",
        value: `${(100 * pickSucessRateYPositive).toFixed(1)}%`,
      },
      {
        name: " - for objects with Y < 0",
        value: `${(100 * pickSucessRateYNegative).toFixed(1)}%`,
      },
      {
        name: "Number of bins",
        value: binFinishedData.length,
      },
    ];

    const eventCount = [
      { name: "Pick success", value: pickSuccessEvents.length },
      { name: "Pick failure", value: pickFailureEvents.length },
      { name: "Unreachable", value: unreachablePicks.length },
      { name: "Collision", value: collisionPicks.length },
      { name: "No image", value: noImagePicks.length },
      { name: "Camera connected", value: cameraConnectionEvents.filter(x => x.status === EVENT_CODE.CAMERA_CONNECTED).length },
      { name: "Camera disconnected", value: cameraConnectionEvents.filter(x => x.status === EVENT_CODE.CAMERA_DISCONNECTED).length },
    ];

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell>Median</TableCell>
                  <TableCell>Min</TableCell>
                  <TableCell>Max</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {minMaxMedian.map((e, idx, precision) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{`${median(e.arr).toFixed(e.precision)}${
                        e.unit || ""
                      }`}</TableCell>
                      <TableCell>{`${Math.min(...e.arr).toFixed(e.precision)}${
                        e.unit || ""
                      }`}</TableCell>
                      <TableCell>{`${Math.max(...e.arr).toFixed(e.precision)}${
                        e.unit || ""
                      }`}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metricValue.map((e, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{e.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper>
            <ResponsiveContainer width={500} height={300}>
              <PieChart>
                <Pie
                  data={eventCount}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={110}
                  fill="#8884d8"
                  label
                >
                  {eventCount.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  verticalAlign="middle"
                  align="right"
                  layout="vertical"
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer title="Picks / mispicks per min">
            <MissPicksBarChart data={dataHist} domain={time_range} />
          </ChartContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer title="Error events">
            <EventBarChart data={dataHist} domain={time_range} />
          </ChartContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer title="Average mispick rate (10 min window)">
            <AvgMissPickRateBarChart data={dataHist_h} domain={time_range} />
          </ChartContainer>
        </Grid>

        <Grid item xs={12}>
          <ChartContainer title="# Picked objects per bin">
            <PicksPerBinChart data={binFinishedData} domain={time_range} />
          </ChartContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <ChartContainer title="Cycle time histogram">
            <CycleTimeHistogram
              data={cycleTimesRounded}
              domain={[minCycleTime - 1, maxCycleTime + 1]}
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={8}>
          <ChartContainer title="Occupied bin volume at bin switch (histogram)">
            <OccupiedVolumeOnBinSwitchHistogram
              data={lastDetectionBeforeBinSwitchRounded}
              domain={[0, 100]}
            />
          </ChartContainer>
        </Grid>

      </Grid>
    );
  }
}

export default PickSuccess;
