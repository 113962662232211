import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import { TextField } from '@material-ui/core';

class HostnameSelector extends Component {
  state = {
    hostname: ""
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      hostname: newProps.hostname
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.updateHostname(this.state);
  }

  render() {
    return (
      <form noValidate onSubmit={this.handleSubmit.bind(this)}>
        <Box display="flex">
          <TextField label="System name" inputProps={{ 'aria-label': 'description' }} onChange={e => {
              this.setState({
                ...this.state,
                hostname: e.target.value,
              });
            }} />
          <Button type="submit" variant="outlined" color="primary" style={{ marginLeft: "1em" }}>
            Go
          </Button>
        </Box>
      </form>
    );
  }
}

export default HostnameSelector;
