import Grid from "@material-ui/core/Grid";
import * as d3 from "d3";
import React, { Component } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Matrix4, Quaternion, Vector3 } from "three";
import ChartContainer from "./ChartContainer";
import { COLORS, EVENT_CODE, PICK_STATUS } from "./Constants";

function PickSuccessDistributionScatterPlot(props) {
  return (
    <ScatterChart height={300} width={300}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="pose.position.x" name="X" type="number" unit="m" />
      <YAxis dataKey="pose.position.y" name="Y" type="number" unit="m" />
      <Tooltip />
      <Legend />
      <Scatter
        name="Pick success"
        data={props.pickSuccessEvents}
        fill={COLORS[0]}
      />
      <Scatter
        name="Pick failure"
        data={props.pickFailureEvents}
        fill={COLORS[1]}
      />
    </ScatterChart>
  );
}

function PickSuccessHistogramAbsoulte(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" unit={props.unit} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="successPicks" fill={COLORS[0]} name="Successful picks" />
        <Bar dataKey="failedPicks" fill={COLORS[1]} name="Miss picks" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function PickSuccessHistogramRelative(props) {
  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" unit={props.unit} />
        <YAxis unit="%" tickFormatter={(v) => v * 100} />
        <Tooltip formatter={(v) => `${(100 * v).toFixed(1)}%`} />
        <Legend />
        <Bar
          dataKey="localMissPickRatio"
          fill={COLORS[3]}
          name="Local miss pick ratio"
        />
        <Bar
          dataKey="globalMissPickRatio"
          fill={COLORS[2]}
          name="Global miss pick ratio"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

function MissPickDirectionPlot(props) {
  return (
    <ScatterChart height={300} width={300}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="uintZ.x" name="X" type="number" />
      <YAxis dataKey="uintZ.y" name="Y" type="number" />
      <Tooltip />
      <Scatter name="Pick failure" data={props.data} fill={COLORS[1]} />
    </ScatterChart>
  );
}

class Analysis extends Component {
  render() {
    const { data } = this.props;

    const pickStatusEvents = data
      .filter((e) => e.code === EVENT_CODE.PICK_STATUS)
      .map((e) => {
        const payload = JSON.parse(e.payload.payload);
        const rot = payload.pose.orientation;
        const quat = new Quaternion(rot.x, rot.y, rot.z, rot.w);
        let unitZ = new Vector3();
        new Matrix4()
          .makeRotationFromQuaternion(quat)
          .extractBasis(new Vector3(), new Vector3(), unitZ);
        const angle = new Vector3(0, 0, -1).angleTo(unitZ); // Negative Z-axis as expressed in robot coordinates.
        return {
          ...e,
          ...payload,
          angle: (angle * 180) / Math.PI,
          uintZ: unitZ,
        };
      });

    const pickOrMissPickEvents = pickStatusEvents.filter((e) =>
      [PICK_STATUS.PICK_SUCCESS, PICK_STATUS.PICK_FAILURE].includes(
        e.event_code
      )
    );
    const pickSuccessEvents = pickOrMissPickEvents.filter(
      (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
    );
    const pickFailureEvents = pickOrMissPickEvents.filter(
      (e) => e.event_code === PICK_STATUS.PICK_FAILURE
    );

    const pickHeightHist = (events) => {
      const hist = d3.bin().value((e) => e.pose.position.z)(events);
      return hist.reduce((acc, el) => {
        const successPicks = el.filter(
          (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
        ).length;
        const failedPicks = el.filter(
          (e) => e.event_code === PICK_STATUS.PICK_FAILURE
        ).length;
        acc.push({
          x: ((el.x0 + el.x1) / 2).toFixed(2),
          successPicks,
          failedPicks,
          localMissPickRatio: failedPicks / (successPicks + failedPicks),
          globalMissPickRatio: failedPicks / pickFailureEvents.length,
        });
        return acc;
      }, []);
    };
    const pickingHeightHistogram = pickHeightHist(pickOrMissPickEvents);

    const angleHist = (events) => {
      const hist = d3
        .bin()
        .value((e) => e.angle)
        .thresholds([0, 5, 15, 45])(events);
      return hist.reduce((acc, el) => {
        const successPicks = el.filter(
          (e) => e.event_code === PICK_STATUS.PICK_SUCCESS
        ).length;
        const failedPicks = el.filter(
          (e) => e.event_code === PICK_STATUS.PICK_FAILURE
        ).length;
        acc.push({
          x: (el.x0 + el.x1) / 2,
          successPicks,
          failedPicks,
          localMissPickRatio: failedPicks / (successPicks + failedPicks),
          globalMissPickRatio: failedPicks / pickFailureEvents.length,
        });
        return acc;
      }, []);
    };
    const pickingAngleHistogram = angleHist(pickOrMissPickEvents);

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <ChartContainer title="Object Z-position histogram">
            <PickSuccessHistogramAbsoulte
              data={pickingHeightHistogram}
              unit="m"
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <ChartContainer title="Object Z-position - Miss pick ratio">
            <PickSuccessHistogramRelative
              data={pickingHeightHistogram}
              unit="m"
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <ChartContainer title="Angle deviation histogram">
            <PickSuccessHistogramAbsoulte
              data={pickingAngleHistogram}
              unit="°"
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <ChartContainer title="Angle deviation - Miss pick ratio">
            <PickSuccessHistogramRelative
              data={pickingAngleHistogram}
              unit="°"
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={4}>
          <ChartContainer title="Distribution in robot base frame">
            <PickSuccessDistributionScatterPlot
              pickSuccessEvents={pickSuccessEvents}
              pickFailureEvents={pickFailureEvents}
            />
          </ChartContainer>
        </Grid>

        <Grid item xs={12} md={4}>
          <ChartContainer title="Direction of Z-axis - Miss picks">
            <MissPickDirectionPlot data={pickFailureEvents} />
          </ChartContainer>
        </Grid>
      </Grid>
    );
  }
}

export default Analysis;
