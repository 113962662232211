import { Typography, makeStyles, Button } from "@material-ui/core";
import Overview from "./Overview";
import Calibration from "./Calibration";
import SystemResourceUsage from "./SystemResourceUsage";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import React from "react";
import { eventFetcherStore } from "./EventFetcherStore";
import { observer } from "mobx-react";
import PickSuccess from "./PickSuccess";
import Analysis from "./Analysis";

const useStyles = makeStyles((theme) => ({
  fixedHeight: {
    height: 240,
  },
}));

export const EventFetcherDisplay = observer(() => {
  const classes = useStyles();

  const state = eventFetcherStore;
  const isFetching = eventFetcherStore.isFetching;
  const eventData = eventFetcherStore.filteredEventData;
  const error = eventFetcherStore.error;

  if (isFetching) {
    return (
      <>
        <Typography variant="h4" color="textSecondary" align="center">
          Fetching events
        </Typography>
        <Box justifyContent="center" className={classes.timePicker}>
          <CircularProgress />
        </Box>
      </>
    );
  } else if (error) {
    return (
      <Typography variant="h4" color="textSecondary" align="center">
        An error occurred while fetching events. Are you connected to system{" "}
        <b>{state.hostname}</b>?
      </Typography>
    );
  } else if (eventData.length > 0) {
    if (state.view === "home") {
      return <Overview data={eventData} />;
    } else if (state.view === "custom") {
      return <PickSuccess data={eventData} />;
    } else if (state.view === "analysis") {
      return <Analysis data={eventData} />;
    } else if (state.view === "calibration") {
      return <Calibration data={eventData} />;
    } else if (state.view === "system-resource-usage") {
      return <SystemResourceUsage data={eventData} />;
    } else {
      return <Overview data={eventData} />;
    }
  } else {
    return (
      <Box pt={4}>
        <Typography variant="h4" color="textSecondary" align="center">
          {eventFetcherStore.filtersActiveCount === 0
            ? "No events"
            : "No events with current filter settings"}
        </Typography>
        {eventFetcherStore.filtersActiveCount > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "1rem",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={eventFetcherStore.clearFilter}
            >
              Clear filter
            </Button>
          </div>
        )}
        <Typography variant="body1" color="textSecondary" align="center">
          There are no events for the selected time range.
          <br />
        </Typography>
      </Box>
    );
  }
});
