import _ from "lodash";
import { makeAutoObservable, makeObservable, observable, reaction } from "mobx";
import history from "./history";

function removeTimezone(date) {
  if (!date) {
    return undefined;
  }
  return new Date(Date.parse(date.replace(/"/g, "").replace(/Z?$/, "Z"))); // Remove quotes, append Z (for UTC time).
}

export function parsePayloadByType(data, type) {
  const jsonPaylodStr = _.get(data, "payload.payload", "{}");
  // return jsonPaylodStr
  //console.log(jsonPaylodStr);
  try {
    return _.get(JSON.parse(jsonPaylodStr), type, undefined);
  } catch {
    return undefined;
  }
}

class EventFetcherStore {
  view = "home";
  hostname = "";
  database = "events";
  startTime = new Date();
  endTime = new Date();

  isFetching = false;
  error = undefined;
  eventData = [];

  get filtersActiveCount() {
    return (
      (this.productFilter === null ? 0 : 1) +
      (this.setupFilter === null ? 0 : 1)
    );
  }

  get filteredEventData() {
    return this.eventData.filter((x) => {
      const setupPayload = parsePayloadByType(x, "setup");
      const productPayload = parsePayloadByType(x, "product");
      return (
        (this.productFilter === null ||
          this.productFilter.includes(productPayload)) &&
        (this.setupFilter === null || this.setupFilter.includes(setupPayload))
      );
    });
  }

  setupFilter = null;
  productFilter = null;

  clearFilter = () => {
    this.setupFilter = null;
    this.productFilter = null;
  };

  fetchEvents = () => {
    if (!this.hostname) {
      return;
    }

    this.isFetching = true;

    fetch(
      `http://${this.hostname}:5984/${
        this.database
      }/_all_docs?include_docs=true&startkey="${this.startTime.toISOString()}"&endkey="${this.endTime.toISOString()}"`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong ...");
        }
      })
      .then((data) =>
        data.rows.map((d) => {
          return {
            ...d.doc,
            time: Date.parse(d.doc._id),
          };
        })
      )
      .then((data) => {
        this.eventData = data;
        this.isFetching = false;
      })
      .catch((error) => {
        this.eventData = [];
        this.isFetching = false;
        this.error = error;
      });
  };

  constructor() {
    const searchParams = new URLSearchParams(window.location.search);
    this.database = searchParams.get("db") ?? this.database;
    this.hostname = searchParams.get("host") ?? this.hostname;
    this.startTime =
      removeTimezone(searchParams.get("start")) ?? this.startTime;
    this.endTime = removeTimezone(searchParams.get("end")) ?? this.endTime;
    this.view = searchParams.get("view") ?? this.view;

    this.productFilter =
      URLArraySerialiser.decode(searchParams.get("productFilter")) ??
      this.productFilter;
    this.setupFilter =
      URLArraySerialiser.decode(searchParams.get("setupFilter")) ??
      this.setupFilter;

    makeAutoObservable(this);

    reaction(
      () => [this.hostname, this.database, this.startTime, this.endTime],
      this.fetchEvents,
      { fireImmediately: true }
    );

    const updateParams = () => {
      let searchParams = new URLSearchParams();
      searchParams.set("host", this.hostname);
      searchParams.set("db", this.database);
      searchParams.set("view", this.view);
      searchParams.set("start", this.startTime.toISOString().slice(0, -8));
      searchParams.set("end", this.endTime.toISOString().slice(0, -8));

      if (
        this.setupFilter &&
        this.setupFilter.length > 0 &&
        this.setupFilter.length < 20
      ) {
        searchParams.set(
          "setupFilter",
          URLArraySerialiser.encode(this.setupFilter)
        );
      } else {
        searchParams.delete("setupFilter");
      }
      if (
        this.productFilter &&
        this.productFilter.length > 0 &&
        this.productFilter.length < 20
      ) {
        searchParams.set(
          "productFilter",
          URLArraySerialiser.encode(this.productFilter)
        );
      } else {
        searchParams.delete("productFilter");
      }
      const url = searchParams.toString();
      history.push(`?${url}`);
    };

    reaction(
      () => [
        this.hostname,
        this.database,
        this.view,
        this.startTime,
        this.endTime,
        this.setupFilter,
        this.productFilter,
      ],
      updateParams,
      { fireImmediately: true }
    );
  }
}

const URLArraySerialiser = {
  encode(array) {
    return array.join("~");
  },
  decode(urlParam) {
    if (!urlParam) {
      return undefined;
    }
    return urlParam.split("~");
  },
};

export const eventFetcherStore = new EventFetcherStore();
