
export const COLORS = [
  "#50b226",
  "#ff8042",
  "#f48680",
  "#ff4242",
  "slateblue",
  "gold",
  "pink",
  "black",
  "brown",
  "grey",
  "darkgreen",
  "yellow",
  "grey1",

  // "#9FE2BF",
  // "#FF7F50",
  // "#6495ED",
  // "#FFBF00",
  // "#DFFF00",
  // "#DE3163",
  // "#40E0D0",
  // "#CCCCFF",
  // "#0088FE",
  // "#F48680",
  // "#50B226",
  // "#FF8042",
  // "#FFE342",
  // "#FF42F2",
  // "#FF4242",
];



export const EVENT_CODE = {
  CAMERA_CONNECTED: 21,
  CAMERA_DISCONNECTED: 22,
  RC_CALIBRATION_SUCCEEDED: 31,
  VALIDATE_CALIBRATION_SUCCEEDED: 34,
  DETECTION_FINISHED: 54,
  PICK_STATUS: 111,
  EVENT_SYSTEM_RESOURCES: 131
};

export const DETECTION_STATUS = {
  SUCCESS: 1,
  NO_IMAGE: 11,
  EMPTY_ROI: 12,
  NO_OBJ_FOUND: -1,
};

export const PICK_STATUS = {
  PICK_SUCCESS: 123,
  PICK_FAILURE: 124,
  UNREACHABLE_OBJECT: 125,
  ROBOT_COLLISION_CAUGHT: 126,
  BIN_FINISHED: 131,
  CYCLE_COMPLETED: 132,
};
