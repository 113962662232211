import React from "react";
import ReactDOM from "react-dom";
import Dashboard from "./Dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./index.css";
import history from './history'


ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
     <Route
        path=""
        render={routeProps => <Dashboard {...routeProps} />}
    />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
