import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { Component } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChartContainer from "./ChartContainer";
import { COLORS, EVENT_CODE } from "./Constants";

function CalibrationBarChart(props) {
  return (
    <ResponsiveContainer width="95%" height={180}>
      <ComposedChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="time"
          //type="number"
          tickFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD")}
          domain={['auto', 'auto']}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          type="number" 
          domain={[ 'auto', dataMax => Math.min(dataMax,5)]}
          stroke={COLORS[0]}
          unit="°"
        /> 
        <YAxis 
          yAxisId="right" 
          orientation="right" 
          type="number" 
          domain={[ 'auto', dataMax => Math.min(dataMax,20)]}
          stroke={COLORS[1]}
          unit="mm"
        />
        <YAxis 
          yAxisId="right" 
          orientation="right" 
          type="number" 
          domain={[ 'auto', 'auto']}
          stroke={COLORS[2]}
          unit="mm"
        />
        <Tooltip
          formatter={(v) => `${v.toFixed(2)}`}
          labelFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD:HH:mm")}
        />
        <Legend />
        <Bar
          dataKey="angle"
          fill={COLORS[0]}
          name="Validation angle error"
          yAxisId="left"
        />
        <Bar
          dataKey="distance"
          fill={COLORS[1]}
          name="Validation distance error"
          yAxisId="right"
        />
        <Bar
          dataKey="calib"
          fill={COLORS[3]}
          name="Calibration"
          yAxisId="right"
        />
        <Brush
          dataKey="time"
          type="number"
          height={20}
          tickFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD:HH:mm")}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function CalibrationScatterChart(props) {
  return (
    <ResponsiveContainer width="95%" height={180}>
      <ComposedChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="time"
          type="number"
          tickFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD")}
          domain={['auto', 'auto']}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          type="number" 
          stroke={COLORS[0]}
          unit="°"
        /> 
        <YAxis 
          yAxisId="right" 
          orientation="right" 
          type="number" 
          stroke={COLORS[1]}
          unit="mm"
        />
        <Tooltip
          formatter={(v) => `${v.toFixed(2)}`}
          labelFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD:HH:mm")}
        />
        <Legend />
        {
          props.calibrations.map(function(calibration){
            return <ReferenceLine 
                      key={calibration.time} 
                      x={calibration.time} 
                      stroke={COLORS[3]}
                      yAxisId="left"
                    />
          })
        }
        <Scatter
          dataKey="angle"
          fill={COLORS[0]}
          name="Validation angle error"
          yAxisId="left"
        />
        <Scatter
          dataKey="distance"
          fill={COLORS[1]}
          name="Validation distance error"
          yAxisId="right"
        />
         <Brush
          dataKey="time"
          type="number"
          height={20}
          tickFormatter={(timeStr) => moment(timeStr).format("yy/MM/DD:HH:mm")}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

class Calibration extends Component {
  render() {
    const { data } = this.props;

    const calibValidEvents = data
      .filter((e) => e.code === EVENT_CODE.VALIDATE_CALIBRATION_SUCCEEDED || e.code === EVENT_CODE.RC_CALIBRATION_SUCCEEDED)
      .map((e) => {
        // Some validation events miss a trailing curly brace, small hack to add if missing
        if (e.payload.payload.charAt(e.payload.payload.length -1 ) !== '}') {
          e.payload.payload = e.payload.payload+"}"
        }
        const payload = JSON.parse(e.payload.payload);
        if (e.code === EVENT_CODE.VALIDATE_CALIBRATION_SUCCEEDED) {
          return {
            ...e,
            time: e.time,
            calib: 0,
            distance: payload.distance * 1000,
            angle: (payload.angle * 180) / Math.PI
          };
        } else { // Ugly hack to include the calibrations in the bar plot
          return {
            ...e,
            time: e.time,
            calib: 20,
            distance: payload.distance ? payload.distance * 1000 : 0,
            angle: payload.angle ? payload.angle * 1000 : 0
        }
      }
      });
    const calibEvents = data
    .filter((e) => e.code === EVENT_CODE.RC_CALIBRATION_SUCCEEDED)
    .map((e) => {
      return {
        ...e,
        time: e.time
      };
    });

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChartContainer title="Calibration events in time">
            <CalibrationScatterChart data={calibValidEvents} calibrations={calibEvents} />
          </ChartContainer>
        </Grid>
        <Grid item xs={12}>
          <ChartContainer title="Calibration events">
            <CalibrationBarChart data={calibValidEvents} calibrations={calibEvents} />
          </ChartContainer>
        </Grid>
      </Grid>
    );
  }
}

export default Calibration;
