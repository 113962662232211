import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Component } from "react";

class DateRangePicker extends Component {
  state = {
    startTime: this.props.startTime,
    endTime: this.props.endTime,
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      startTime: newProps.startTime,
      endTime: newProps.endTime,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.updateTimes(this.state);
  }

  render() {
    return (
      <form noValidate onSubmit={this.handleSubmit.bind(this)}>
        <Box display="flex" style={{ paddingBottom: "1em" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="Start time"
              value={this.state.startTime}
              onChange={(val) => {
                this.setState({ ...this.state, startTime: val });
              }}
              onError={console.log}
              format="yyyy/MM/dd HH:mm"
            />
            <KeyboardDateTimePicker
              style={{ margin: "0 1em" }}
              variant="inline"
              ampm={false}
              label="End time"
              value={this.state.endTime}
              onChange={(val) => {
                this.setState({ ...this.state, endTime: val });
              }}
              onError={console.log}
              format="yyyy/MM/dd HH:mm"
            />
          </MuiPickersUtilsProvider>
          <Button type="submit" variant="outlined" color="primary">
            Go
          </Button>
        </Box>
      </form>
    );
  }
}

export default DateRangePicker;
